.cust-list-col {
  padding: 10px;
}

.cust-list-avtr-col {
  margin-top: 10px;
  margin-bottom: 10px;
}

.admin-customer-list {
  cursor: pointer;
  .ant-avatar-string {
    line-height: 0%;
  }
  .cust-code-avtr {
    background-color: #2d3436;
    color: #f5f6fa;
    .cust-code-extrt-multiple {
      line-height: 20px;
      margin-top: 13px;
    }
    .cust-code-extrt-single {
      line-height: 20px;
      margin-top: 15px;
    }
    .report-cnt {
      margin-top: 2px;
      line-height: 6px;
      font-size: 8px;
    }
  }
}

.cust-list-back-btn {
  .anticon-left-circle {
    font-size: 20px;
    font-weight: bold;
    color: #636e72;
  }
  .cust-title {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #636e72;
    padding-left: 5px;
  }
}

.cust-wise-report {
  padding: 10px;
}
.row-has-error {
  border: 1px solid #d63031;
  padding: 1px !important;
  line-height: 20px;
  width: 20px;
} 
.updated-row{
  background-color: #d2f4dd !important;
}
.reports-title {
  width: 80%;
  white-space: 'nowrap';
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1%;
}

@import "./cds/dashboard.scss";

.ssp-dashboard {
    .ant-card {
        &:hover {
            transform: none;
        }
        &-body {
            padding: 10px !important;
        }
    }
    .ag {
        &-theme-balham {
            font-size: 13px !important;
        }
        &-header {
            border-color: rgba(250, 250, 250, 1) !important;
            background-color: rgba(250, 250, 250, 1) !important;
        }
        &-root-wrapper {
            border: rgba(250, 250, 250, 1) !important;
        }
        &-center-cols-container {
            width: 100% !important;
        }
        &-header-cell {
            background-color: rgba(250, 250, 250, 1);
            &:last-child {
                &::after {
                    border: none !important;
                    content: none;
                }
            }
        }
        &-row {
            background-color: #FFFFFF;
            border-color: rgba(240, 240, 240, 1);
            color: #333333 !important;
            &-hover {
                background-color: rgba(33, 150, 243, 0.1) !important;
                .ag-cell-value {
                    color: #333333 !important;
                }
            }
        }
        &-grid-container {
            .ant-picker-input {
                input {
                    &:focus {
                        border: none !important;
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
    a {
        color: #0099FF !important;
    }

    .overall-status {
        background-color: #dfe6e9;
        color: #222f3e;
    }

    .ssp-timeline-container {
        counter-reset: step;
        padding: 5% 2% 5% 3%;
        border: 1px solid #d9d9d9;
        background-color: #d9d9d9;
        .ssp-timeline {
            background-color: white;
            padding: 13px;
            z-index: 999999;
            .progressbar {
                margin-top: -1%;
                li {
                    list-style-type: none;
                    width: 7.6%;
                    float: left;
                    font-size: 9px;
                    position: relative;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #1e272e;
                    white-space: normal;
                    word-wrap: break-word;
                    &::before {
                        width: 10px;
                        height: 10px;
                        content: '';
                        counter-increment: step;
                        line-height: 20px;
                        display: block;
                        text-align: center;
                        margin: 7px auto 10px auto;
                        border-radius: 50%;
                        z-index: 2;
                        border: 3px solid #55b776;
                    }
                    &::after {
                        width: 76%;
                        height: 2px;
                        content: '';
                        position: absolute;
                        background-color: #55b776;
                        left: -50%;
                        margin-left: 15%;
                        margin-top: -17px;
                        z-index: 0;
                    }
                    &:first-child::after {
                        content: none;
                    }
                    &.initial {
                        padding-top: 13px;
                        width: 6%;
                        height: 120px;
                        margin-top: -47px;
                        &::after {
                            top: 74px;
                        }
                        &::before {
                            margin: 37px auto 15px auto !important;
                        }
                        &.last {
                            margin-right: 10px;
                        }
                    }
                    &.active {
                        font-weight: bold;
                        color: #165616;
                        &::before {
                            -moz-transition:all 0.5s ease-in-out;
                            -webkit-transition:all 0.5s ease-in-out;
                            -o-transition:all 0.5s ease-in-out;
                            -ms-transition:all 0.5s ease-in-out;
                            transition:all 0.5s ease-in-out;
                            -webkit-animation: 1.5s timelineBlink normal infinite ease-in-out;
                            -moz-animation: 1.5s timelineBlink normal infinite ease-in-out;
                            -ms-animation: 1.5s timelineBlink normal infinite ease-in-out;
                            -o-animation: 1.5s timelineBlink normal infinite ease-in-out;
                            animation: 1.5s timelineBlink normal infinite ease-in-out;
                            border: 3px solid #55b776;
                            height: 12px;
                            margin: 2px auto 10px auto;
                            width: 12px;
                        }
                        &~::after {
                            background-color: #55b776;
                        }
                        &.red-class {
                            &::before {
                                content: '';
                                background-color:#ffbd05 !important;
                            }
                        }
                        &.green-class {
                            &::before {
                                content: '';
                                background-color:#fff !important;
                            }
                        }
                    }
                    &.done {
                        color: #1e272e;
                        &+::after {
                            background-color: #55b776;
                        }
                        &::before {
                            width: 10px;
                            height: 10px;
                            content: '';
                            counter-increment: step;
                            line-height: 20px;
                            display: block;
                            text-align: center;
                            margin: 3px auto 10px auto;
                            border-radius: 50%;
                            border: 3px solid #55b776;
                            background-color:#55b776;
                        }
                    }
                    &.portal {
                        background: linear-gradient(180deg, #d2dae2 35%, #ffffff 37%, #ffffff 61%, #d2dae2 30%);
                        padding-top: 13px;
                        width: 6%;
                        height: 120px;
                        margin-top: -47px;
                        &::after {
                            top: 74px;
                        }
                        &::before {
                            margin: 37px auto 15px auto !important;
                        }
                        &.first {
                            border-top-left-radius: 18px;
                            border-bottom-left-radius: 18px;
                        }
                        &.last {
                            border-top-right-radius: 18px;
                            border-bottom-right-radius: 18px;
                            margin-right:10px;
                        }
                    }
                    &.uat {
                        background: linear-gradient(180deg,#d2dae2 35%, #ffffff 37%, #ffffff 61%,#d2dae2 30%);
                        padding-top: 13px;
                        width: 8%;
                        height: 120px;
                        margin-top: -47px;
                        &::after {
                            top: 74px;
                        }
                        &::before {
                            margin: 37px auto 15px auto !important;
                        }
                        &.first {
                            border-top-left-radius: 18px;
                            border-bottom-left-radius: 18px;
                        }
                        &.last {
                            border-top-right-radius: 18px;
                            border-bottom-right-radius: 18px;
                            margin-right:10px;
                        }
                    }
                    &.production {
                        background: linear-gradient(180deg,#d2dae2 35%, #ffffff 37%, #ffffff 61%,#d2dae2 30%);
                        padding-top: 13px;
                        width: 7%;
                        height: 120px;
                        margin-top: -47px;
                        &::after {
                            top: 74px;
                        }
                        &::before {
                            margin: 37px auto 15px auto !important;
                        }
                        &.first {
                            border-top-left-radius: 18px;
                            border-bottom-left-radius: 18px;
                        }
                        &.last {
                            border-top-right-radius: 18px;
                            border-bottom-right-radius: 18px;
                        }
                    }
                    .portal-title-first {
                        margin-top: -70px;
                        margin-left: 110%;
                        font-size: 13px;
                        font-weight: 700;
                        position: absolute;
                        z-index: 5;
                    }
                    .portal-status-first {
                        margin-top: 15px;
                    }
                    .uat-title-first {
                        margin-top: -70px;
                        margin-left: 230%;
                        font-size: 13px;
                        font-weight: 700;
                        position: absolute;
                        z-index: 5;
                    }
                    .uat-status-first {
                        margin-top: 15px;
                    }
                    .production-title-first {
                        margin-top: -70px;
                        margin-left: 30%;
                        font-size: 13px;
                        font-weight: 700;
                        position: absolute;
                        z-index: 5;
                    }
                    .production-status-first {
                        margin-top: 15px;
                    }
                    &.cancelled-status {
                        color: #e74c3c;
                        padding-top: 16px;
                        &::before {
                            width: 10px;
                            height: 10px;
                            content: 'X';
                            counter-increment: step;
                            line-height: 10px;
                            display: block;
                            text-align: center;
                            border-radius: 50%;
                            background-color: transparent;
                            z-index: 2;
                            font-size: 12px;
                            font-weight: bold;
                            color: #e74c3c;
                            border: none;
                            padding-left: 2px;
                        }
                    }
                }
            }
        }
    }
  
    .ag-menu {
        overflow: unset !important;
    }

    .wrap-text {
        line-height: 20px;
        padding: 5px 0px;
        word-break: break-word;
        text-align: center;
    }

    .timeline-toggle-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .timeline-toggle-content {
            word-break: break-word;
            text-align: center;
            margin-left: 3px;
            cursor: pointer;
        }
    }
}
.txn-step {
    display: flex;
    flex-direction: row;
    padding: 15px 5px 2px 5px;
    border-bottom: 1px solid #f2f2f2;
    font-size: 14px !important;
    div {
        flex: 0 1 100px;
    }

    &:nth-child(even) {
        background: #fafafa;
    }

    .ant-steps-item-finish {
        .ant-steps-icon-dot {
            background: rgb(58, 227, 116) !important;
        }
        .ant-steps-item-tail {
            &::after {
                background: rgb(58, 227, 116) !important;
            }
        }
    }
    .ant-steps-item-process {
        .ant-steps-icon-dot {
            -moz-transition:all 0.5s ease-in-out;
            -webkit-transition:all 0.5s ease-in-out;
            -o-transition:all 0.5s ease-in-out;
            -ms-transition:all 0.5s ease-in-out;
            transition:all 0.5s ease-in-out;
            -webkit-animation: 1.5s blink normal infinite ease-in-out;
            -moz-animation: 1.5s blink normal infinite ease-in-out;
            -ms-animation: 1.5s blink normal infinite ease-in-out;
            -o-animation: 1.5s blink normal infinite ease-in-out;
            animation: 1.5s blink normal infinite ease-in-out;
        }
    }
    .ant-steps-item-content {
        width: 115px !important;
        margin-top: 2px !important;
    }
    .ant-steps-item-title {
        font-size: 12px !important;
        padding-left: 25px !important;
    }
}

.txn-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
    & > div {
        flex: 0 1 100px;
    }
    &>.txn-header-right {
        display: flex;
        &>div {
            width: 100px;
            font-weight: 600;
        }
    }
}

@keyframes blink {
    0% { background-color: #ff9f43; }
    50% { background-color: #f9ca24; }
    100% { background-color: #f6e58d; }
}

@keyframes timelineBlink {
    0% { background-color: #f9ca24; }
    50% { background-color: #ffffff; }
    100% { background-color: #f9ca24; }
}
.cds-form {
  height: calc(100vh - 80px);
  overflow-y: scroll;
  padding: 1%;
  .steps-content {
    margin: 0px 0px 25px;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
    -o-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -ms-border-radius: 5px;
    -moz-border-radius: 5px;
    padding: 0 30px 25px;
  }

  .steps-action {
    margin-top: 24px;
    margin-left: 13px;
  }
  [data-theme="dark"] .steps-content {
    margin-top: 16px;
    border: 1px dashed #303030;
    background-color: rgba(255, 255, 255, 0.04);
    color: rgba(255, 255, 255, 0.65);
    padding-top: 80px;
  }
  .steps-content .wizard-header {
    padding: 9px 30px 11px;
    background: #ef373e;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 -30px 24px;
  }
  .steps-content .wizard-header .heading {
    font-size: 22px;
    color: #fff;
    font-weight: 700;
    margin: 0;
  }
  .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
    width: 51px;
    height: 37px;
    margin: 0 8px 0 0;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #ef373e;
    background: #ef373e;
    font-weight: 600;
    border-color: #ef373e;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    float: left;
    margin-right: 0px;
  }
  .ant-steps-item-active
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #fff;
    background: #ef373e;
    font-size: 16px;
    font-weight: 600;
    min-width: 150px;
  }
  .ant-steps-vertical > .ant-steps-item-active .ant-steps-item-title {
    line-height: 37px;
    background: #ef373e;
    color: #fff;
    min-width: 150px;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
    width: 51px;
    height: 37px;
    margin: 0 0px 0 0;
    font-size: 16px;
    font-family: "Avenir", Helvetica, sans-serif;
    line-height: 32px;
    text-align: center;
    border: 1px solid #dfe6e9;
    background: #dfe6e9;
    font-weight: 600;
    border-color: #dfe6e9;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #465660;
    background: #dfe6e9;
    font-size: 16px;
    font-weight: 600;
    min-width: 151px;
  }
  .ant-steps-vertical > .ant-steps-item-wait .ant-steps-item-title {
    line-height: 37px;
    background: #dfe6e9;
    color: #465660;
    min-width: 150px;
  }
  .ant-steps-vertical > .ant-steps-item-wait .ant-steps-item-icon {
    background: #dfe6e9;
    color: #465660;
  }
  .ant-steps-item-icon {
    border-radius: 0px;
    transition: none !important;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon {
    position: relative;
    top: 1.5px;
    background: #dfe6e9 !important;
    color: #465660 !important;
    line-height: 1;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: #fff;
  }
  .ant-steps {
    color: #fff;
  }
  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon {
    border-color: #dfe6e9 !important;
    background: #dfe6e9 !important;
  }
  .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-description {
    color: #fff;
  }
  .ant-steps
    > .ant-steps-item-current
    > .ant-steps-item-container
    > .ant-steps-item-content:hover
    .ant-steps-item-title:hover {
    color: #fff !important;
  }
  .ant-steps > .ant-steps-item-current > .ant-steps-item-container:hover {
    color: #fff !important;
  }
  .ant-steps > .ant-steps-item-current:hover {
    color: #fff !important;
  }
  .ant-steps
    .ant-steps-item-current
    > .ant-steps-item-container[role="button"]:hover {
    color: #fff !important;
  }
  .ant-steps
    .ant-steps-item-current
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon {
    color: #fff !important;
  }
  .ant-steps
    > .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content:hover
    .ant-steps-item-title:hover {
    color: #465660 !important;
  }
  .ant-steps > .ant-steps-item-wait > .ant-steps-item-container:hover {
    color: #465660 !important;
  }
  .ant-steps > .ant-steps-item-wait:hover {
    color: #465660 !important;
  }
  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container[role="button"]:hover {
    color: #465660 !important;
  }
  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon {
    color: #465660 !important;
  }
  .ant-steps-item-current
    > .ant-steps-item-container
    > .ant-steps-item-content::after {
    content: "";
    position: relative;
    top: -3px;
    border-top: 18px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 30px solid #ef373e;
  }
}

.report-header-details-body {
  height: 270px;
  overflow: auto;
}

.report-modal {
  width: calc(44%) !important;
}

.send-frequency {
  padding: 46px 10px;
}

.flex-column {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}

.div-flex {
  flex: 5%;
  padding: 20px;
}
$dashboard-card-bg-color: #d9d9d9; // Appears 2 times

.card-holder {
	cursor: pointer;
	padding: 18px 8px 9px !important;
}
.dashboard {
	padding: 5px;
	.user-report-card >.ant-card-body {
		padding: 0;
	}
	.ant-card-head-title {
		text-align: center;
	}
	.ant-card-body {
		text-align: right;
	}
	.ant-card {
		border-radius: 2px;
		box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.03);
		transition: transform 0.2s;
		p {
			&.card-title {
				background-color: $dashboard-card-bg-color;
				border-bottom: 1px solid $dashboard-card-bg-color;
				font-size: 16px;
				margin-bottom: 0;
				padding: 10px 0;
				text-align: center;
				transition: all 0.1s;
				margin-top: 0px;
			}
			&.card-content {
				margin-bottom: 0;
				padding: 25px 15px;
				text-align: center;
			}
			&.card-footer {
				border-top: 1px solid #f7f7f7;
				font-size: 12px;
				margin: 0 25px;
				padding: 10px;
				text-align: center;
			}
		}
		&:hover {
			transform: scale(1.02);
		}
	}
}
  





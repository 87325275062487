.cpt {
    .ant-row{
        padding: 5px 5px 0 5px;
    }
    .ant-radio-group{
        position: absolute;
        padding: 5px 0 0 0;
    }
    .ant-radio-button-wrapper{
        background-color: #e0e0e0;
        border: 1px solid #c2c2c2;
        border-color: #c2c2c2;
        height: 26px;
        line-height: 24px;
        padding: 0px 30px;
        &.ant-radio-button-wrapper-checked{
            border-color: #EF373E !important;
        }
        &:first-child{
            border-radius: 4px 0 0 4px;
        }
        &:last-child{
            border-radius: 0 4px 4px 0;
        }
    }    
    .grid-actions-top {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 5px;
        &> div {
            margin-left: 10px;
            .ant-icon {
                font-size: 16px;
            }
        }
    }
    .ag-tool-panel-wrapper {
        min-width: 300px;
        max-width: 310px;
    }
    .ant-divider-dashed{
        margin: 16px 0 !important;
    }
    .ant-divider-horizontal{
        margin: 16px 0 !important;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: initial;
    }
    
  }
  .ant-modal.confirm-popup{
    width: 540px !important;
  }
  .confirm-popup{
    .ant-modal-header{
        padding: 16px 24px;
    }
    .ant-modal-body{
        padding: 10px 16px;
    }
    .ant-form-item > .ant-form-item-label {
        width: 16%;
        white-space: normal;
        text-align: left;
    }
    .update-checkbox{
        padding-left: 16%;
    }
    .ant-empty span{
        color: rgba(0,0,0,0.35);
    }
    .tablular {
        height:135px;
        .ant-spin-blur{
            opacity: 0;
        }
        .ant-empty-normal{
            padding: 32px 0;
            margin:0;
        }
    }
   .ant-form-item-label{
       font-weight: 500;
   }
   .ant-checkbox + span{
    font-weight: 500;
   }
   .ant-alert-info{
    background-color: #e6f7ff !important;
    border: 1px solid #91d5ff !important;
    .ant-alert-icon {
        color: #1890ff !important;
      }
   }
  div.ag-theme-alpine div.ag-row {
    font-size: 12px !important;
  }
  .ag-header-cell-text{
    font-weight: 500 !important;
  }
.ag-theme-alpine{
    .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group{
        line-height: 20px;
        padding: 5px;
    }
    .ag-header-cell, .ag-header-group-cell{
        padding-left: 5px;
        padding-right: 5px;
    }
}
  .ant-form-item .ant-input-textarea-show-count::after{
    font-size: 11px;
  }
  .ant-divider-horizontal{
    margin: 12px 0 5px !important;
  }
  .ant-divider-horizontal.ant-divider-with-text{
    font-size: 14px;
    border-top-color: rgb(239, 55, 62);
    .ant-divider-inner-text{
        font-weight: bold;
    }
  }
  .alert-tag{
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    .anticon-info-circle{
        color:#1890ff;
    }
  }
  }
  
  
  
.candidate-item {
    .agility-admin {
        .agility-admin-action {
            margin: 5px;
            &> button {
                margin-right: 5px;
            }
        }   
        .row-has-error {
            border: 1px solid #d63031;
            padding: 1px !important;
            line-height: 20px;
            width: 20px;
        }   
    }
    .supplier {
        .supplier-action {
            margin: 5px;
        }
        .row-has-error {
            border: 1px solid #d63031;
            padding: 1px !important;
            line-height: 20px;
            width: 20px;
        }   
    }
    
    .rsm-plant {
        .rsm-plant-action {
            margin: 5px;
            &> button {
                margin-right: 5px;
            }
        }
        .row-has-error {
            border: 1px solid #d63031;
            padding: 1px !important;
            line-height: 20px;
            width: 20px;
        }   
    }

    .ag-grid-container{
        .ant-select{
            width: 100%;
            padding: 0;
            height: 26px !important;
        }
        .ant-select.error{
            border: 1px solid #ff7675 !important;
            box-shadow: 0 0 10px #fab1a0 !important;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            height: 24px;
            padding: 0 11px;
        }
    }

    .status-error {
        border: 1px solid #d63031;
        line-height: 24px;
        height: 25px;
        .status-value {
            margin-left: 5px;
        }
    }
    

    .ant-tabs-nav .ant-tabs-tab {
        border: 1px solid #f0f0f0 !important;
        border-bottom-color: #FFFFFF !important;
    }

    .ant-tabs-nav-wrap {
        margin-left: 3px;
    }

    .grid-actions-top {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 5px;
        &> div {
            margin-left: 10px;
            .ant-icon {
                font-size: 16px;
            }
        }
    }

    .ag-tool-panel-wrapper {
        min-width: 300px;
        max-width: 310px;
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 8px;
    visibility: hidden;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.ag-theme-balham {
    .ag-row-editing {
        background-color: #dff9fb !important;
        border-color: #7ed6df !important;
    }
    .ag-cell-inline-editing {
        border-color: #dff9fb !important;
    }
}
.rsm-plant .ant-picker{
    width: 230px;
    height: 26px;
}
.rsm-plant .ant-select{
   width: 100%;
}

.rsm-plant .ant-picker.error{
    border: 1px solid #ff7675 !important;
    box-shadow: 0 0 10px #fab1a0 !important;
}

.grid-actions-top{
	.anticon-download, .anticon-sync, .clear-filter .anticon{
		cursor: pointer;
	}
}
.questionnaire-form{
    overflow-y: scroll;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    padding: 0px 15px;
    .ant-form-inline{
        display: block;
    }
    .left-box{
        float: left;
    }
    .right-box{
        float:right;
    }
    .edi_details .ant-col{
        max-width: 61%;
    }
    .ant-form-inline .buttons .ant-form-item{
        margin-right: 0;
    }
    .ant-form-inline .ant-form-item > .ant-form-item-label {
        width: 35%;
        margin: 0 2%;
        white-space: normal;
        text-align: left;
    }
    .ant-divider-inner-text{
        font-weight: 500;
        padding: 0 0.5em;
    }
}
.form-item{
    margin: 2% 0;
}
.ant-form-item-control-input-content .pdf-object{
    width: 600px;
    height: 200px;
    margin-left: 25px;
}
.ant-form-item-label > label {
    height: auto;
}
.ant-form legend{
    margin-left: 1%;
    color: #EF373E !important;
}
.buttons{
    display: inherit;
    margin: 1%;
}
.cell-span {
    background-color: #00e5ff;
}
// testing form less
.ag-details-row .ag-root-wrapper.ag-layout-normal{
	height: 299px !important;
}
.ag-theme-balham .ag-details-row .ag-paging-panel {
    display: none;
}
.ag-theme-balham .ag-details-row{
	padding: 0px !important;
}
.ag-details-row .ag-body-horizontal-scroll{
    display: none;
}
.ag-details-row .ag-theme-balham .ag-header{
    display: none !important;
}

.testing-form-ui {
    .row-has-error {
        border: 1px solid #d63031;
        padding: 1px !important;
        line-height: 20px;
        width: 20px;
    }
    
    .ag-grid-container{
        .ant-select{
            width: 100%;
            padding: 0;
            height: 26px !important;
        }
        .ant-select.error{
            border: 1px solid #ff7675 !important;
            box-shadow: 0 0 10px #fab1a0 !important;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            height: 24px;
            padding: 0 11px;
        }
        .ant-picker.error{
            border: 1px solid #ff7675 !important;
            box-shadow: 0 0 10px #fab1a0 !important;
        }
    }

    .ag-cell {
        border-right: 1px solid #d9dcde !important;
    }
}

.testing-form-view-edi {
    width: auto !important;
    min-width: 650px;
    max-width: 720px;
    .ant-modal-confirm-content {
        height: 340px;
        overflow-y: auto;
        font-size: 13px;
    }
}
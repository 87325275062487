body {
    &::-webkit-scrollbar {
      display: none; 
     -ms-overflow-style: none; 
     scrollbar-width: none; 
   }
  }
footer {
    color: "#000";
}

.confirm-login {
    .continue-or-divider {
        margin: 5px 0px !important;
    }

    .ant-card-head-title {
        font-size: 18px !important;
        color: #34495e;
    }
}

$grid-head-bg-color: #eff1f4;

.ag-grid-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	.grid-actions-top {
		display: flex;
		flex-direction: row-reverse;
		margin: 5px;
		right: 10px;
		top: 27px;
		z-index: 1000;
		.anticon {
			font-size: 18px;
			margin-left: 10px;
			cursor: pointer;
		}
	}
	.ag-header {
		background-color: $grid-head-bg-color;
		background-color: var(--ag-header-background-color, $grid-head-bg-color);
		border-bottom-color: $grid-head-bg-color;
		border-bottom-color: var(--ag-border-color, $grid-head-bg-color);
	}
	.ag-root-wrapper {
		border-color: $grid-head-bg-color;
		border-color: var(--ag-border-color, $grid-head-bg-color);
		border-radius: 2px;
	}
	input {
		&:focus {
			outline: none !important;
			border:1px solid #636e72 !important;
			box-shadow: 0 0 10px #b2bec3 !important;
		}
		&.error {
			outline: none !important;
			border:1px solid #ff7675 !important;
			box-shadow: 0 0 10px #fab1a0 !important;
		}
	}

	.ant-checkbox-checked {
		.ant-checkbox-inner {
			background-color: #1890ff !important;
			border-color: #1890ff !important;
		}
	}
}

.ag-dnd-ghost {
	width: 200px !important;
}
.testlogs {
    .ant-row{
        padding: 5px 5px 0 5px;
    }
    .grid-actions-top {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 5px;
        &> div {
            margin-left: 10px;
            .ant-icon {
                font-size: 16px;
            }
        }
    }
    .ag-tool-panel-wrapper {
        min-width: 300px;
        max-width: 310px;
    }
    .ant-divider-dashed{
        margin: 16px 0 !important;
    }
    .ant-divider-horizontal{
        margin: 16px 0 !important;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: initial;
    }
    
}

.testlogs-modal {
	.ant-modal-body {
		height: 250px;
		overflow-y: auto;
		padding-top: 5px;
	}
}
.ant-layout-sider-collapsed {
	.ant-layout-sider-children {
		.ant-menu-inline-collapsed {
			.ant-menu-item {
				padding: 0 27px;
			}
		}
		.ant-menu-inline-collapsed>.ant-menu-item {
			.anticon {
				font-size: 25px;
			}
		}
		.profile-space {
			.gx-avatar-name {
				display: none;
			}
		}
	}
}
.ant-layout-sider {
	.ant-menu-item {
		.anticon {
			font-size: 20px;
		}
	}
}
.ant-menu-submenu-title {
	.anticon {
		font-size: 20px;
	}
}
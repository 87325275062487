.edi-selection-form{
    margin: 10px 5px 5px 5px;
    .select-padding{
        padding: 0px 11px;
    }
    .first{
        .ant-tree-switcher_open + .ant-tree-checkbox, .ant-tree-switcher_close + .ant-tree-checkbox {
            display: none !important;
        }
    }
    .second{
        .ant-tree-switcher, .ant-tree-list-holder .ant-tree-indent {
            width: 0;
        }
        .ant-tree-list-holder-inner{
            align-items: center;
        }
    }
    .hide-children{
        display:none;
        animation: slide-up 1s ease-out;
    }
    .show-children{
        display:block;
        animation: slide-down 1s ease-out;
    }
    .hidden, .visible {
        transition: all 1s;
        display: inline-block;
        font-size: 20px;
        width: 15px;
        cursor: pointer;
    }
    .expanderName {
        padding: 0 8px;
    }
    @keyframes slide-down {
        0% {
            opacity: 0;
        }
        
        100% {
            opacity: 1;
        }
    }
    @keyframes slide-up {
        0% {
            opacity: 0;
        }
        
        100% {
            opacity: 1;
        }
    }
}
.left-tray{
    float: left;
    width: 50%;
}
.right-tray{
    float: right;
    width: 50%;
}
.data-tray{
    height: 450px;
    overflow: auto;
}
.mandatory-text{
    text-align: center;
    font-weight: 600;
}

.request-from {
    height: calc(100vh - 80px);
    overflow-y: scroll;
    .customer-form {
        .customerCommonActions {
            margin: 5px;
            &> button {
                margin-right: 5px;
            }
            float: right;
            .greenBtn {
                color: #F5F5F5;
                background-color: #3eb05b;
                border: #1a8c37
                
            }
            .redBtn {
                color: #F5F5F5;
                background-color:#EF373E;
                border: #EF373E
                
            }
            .blueBtn {
                color: #F5F5F5;
                background-color:#0e5ade ;
                border:blue;
                
            }
        }

        .reviewerPart {
            margin: 5px;
            &> button {
                margin-right: 5px;
            }
            float: left;
            .greenBtn {
                color: #F5F5F5;
                background-color: #3eb05b;
                border: #1a8c37
                
            }
            .redBtn {
                color: #F5F5F5;
                background-color:#EF373E;
                border: #EF373E
                
            }
            .blueBtn {
                color: #F5F5F5;
                background-color:#0e5ade ;
                border:blue;
                
            }
        }

        .customerSelfActions {
            margin: 5px;
            &> button {
                margin-right: 5px;
            }
            float: left;
            .greenBtn {
                color: #F5F5F5;
                background-color: #3eb05b;
                border: #1a8c37
            }
            .redBtn {
                color: #F5F5F5;
                background-color:#EF373E;
                border: #EF373E
                
            }
        }
    }
}

.tokenEmail {
    height: 172px;
}

.fileup-file {
    background-color: #F5F5F5;
    border: 1px solid #DBDBDB;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font: 12px Verdana, Geneva, sans-serif;
    margin-bottom: 5px;
    max-width: 530px;
    padding: 10px;
    position: relative;
    overflow: hidden;
}

.fileup-file .fileup-remove {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
    height: 16px;
    background-color: #F5F5F5;
}

.fileup-file .fileup-description {
    padding-right: 15px;
    padding-top: 7px;
    word-break: break-all;
}

.file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.customDeleteBtn {
    padding-top: 2px;
    padding-bottom: 3px;
    padding-right: 5px;
    padding-left: 5px;
    color: #F5F5F5;
    background-color:#EF373E;
    border: #EF373E;
    &:hover, &:active {
        color:#F5F5F5;
    }
    margin-right: 5px;
}

.customGreenBtn {
    color: #F5F5F5;
    background-color: #3eb05b;
    border: #1a8c37
}

.customRedBtn {
    color: #F5F5F5;
    background-color:#EF373E;
    border: #EF373E
}

.customBlueBtn {
    color: #F5F5F5;
    background-color:#0e5ade ;
    border:blue;
}